<template>
  <v-sheet class="px-4 py-1">
    <h3 class="mt-1">
      {{ templateInfo.applicationName }}
      <span v-if="templateInfo.applicationName === 'Dims'">{{ templateInfo.applicationData?.agreementName }}</span>
    </h3>
    <v-row class="mt-2 ml-0">
      <h1 v-if="!editName" class="ma-0 my-2">
        {{ templateInfo.name }}
        <v-btn icon="mdi-pencil" @click="startEditName()" />
      </h1>
      <!-- inline edit of title -->
      <div v-if="editName" class="mb-5 my-4">
        <v-text-field
          v-model="editedName"
          hide-details
          :disabled="isLoading"
          class="mb-2"
          label="Rediger titel"
          variant="outlined"
        ></v-text-field>
        <v-btn
          @click="updateName"
          class="primary-button-mini"
          :loading="isLoading"
        >
          Gem
        </v-btn>
        <v-btn
          @click="cancelNameEdit"
          :disabled="isLoading"
          class="ml-2 secondary-button-mini"
        >
          Annuller
        </v-btn>
      </div>
      <v-chip>{{ templateInfo.applicationData?.awardForm === 'DirectAward' ? 'Direkte tildeling' : 'DIS' }}</v-chip>
      <v-spacer />
      <v-btn
        class="secondary-button-mini bg-skiGrey mr-2"
        append-icon="mdi-microsoft-excel"
        @click="excelExportList()"
      >
        Eksporter til Excel
      </v-btn>
    </v-row>
    <div>

      <v-divider></v-divider>
      <!-- drafts -->
      <div v-if="templateInfo.drafts && templateInfo.drafts.length > 0">
        <div v-for="draft in templateInfo.drafts" :key="draft.id">
          <DraftTemplate
            :key="draft.id"
            :templateInfo="templateInfo"
            :templateSummary="draft"
            :agreementName="agreementName"
            @updated="refresh"
          />
        </div>
      </div>
      <div v-else>
        <DraftTemplate
          :key="0"
          :templateInfo="templateInfo"
          :agreementName="agreementName"
          @updated="refresh"
        />
      </div>
      <div class="my-2">
        <v-btn
          v-if="templateInfo.current"
          @click="createVersion(templateInfo.current)"
          class="secondary-button-mini ml-2"
        >Dan ny kladde</v-btn>
      </div>
      <v-divider></v-divider>
      <!-- current -->
      <h4 class="mt-6 mb-3">Aktiv version</h4>
      <div v-if="templateInfo.current">
        <v-table fixed-header density="compact" class="my-2">
          <thead>
            <tr>
              <th>Offentliggjort den</th>
              <th>Offentliggjort af</th>
              <th>Version</th>
              <th>Versions-ID</th>
              <th>Release note</th>
              <th>Vis</th>
              <th>Antal indkøb</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="templateInfo.current.id" class="my-1">
              <td>{{ displayPublishedDate(templateInfo.current) }}</td>
              <td>{{ templateInfo.current.lastUpdatedBy?.fullName }}</td>
              <td class="text-right">{{ templateInfo.current.publishedVersionNumber }}</td>
              <td>{{ displayExtendedVersionId(templateInfo.current) }}</td>
              <td><v-btn size="x-small" @click="releaseNote(templateInfo.current)" v-if="templateInfo.current.publishedNotes">{{ displayPublishedNotes(templateInfo.current) }}</v-btn></td>
              <td><v-btn size="x-small" icon="mdi-file-eye-outline" variant="text" @click="preview(templateInfo.current)"></v-btn></td>
              <td>{{ templateInfo.current.uses.length }}</td>
              <td>   {{ templateInfo.current.id.slice(0, 8) }}...
                <CopyToClipboard :text="templateInfo.current.id" /></td>
            </tr>
          </tbody>
        </v-table>
        <CopyToEnvironment :templateId="templateInfo.current.id" />
      </div>
      <div v-else>
        <i>Ingen aktiv version</i>
      </div>
      <br />
      <br />

      <v-divider></v-divider>
      <!-- retired -->
      <h4 class="mt-6 mb-3">Tidligere versioner</h4>
      <div v-if="templateInfo.oldVersions.length > 0">
        <v-table height="300px" fixed-header density="compact">
          <thead>
            <tr>
              <th>Offentliggjort den</th>
              <th>Offentliggjort af</th>
              <th>Version</th>
              <th>Versions-ID</th>
              <th>Release note</th>
              <th>Vis</th>
              <th>Antal indkøb</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="version in templateInfo.oldVersions.toSorted(sortVersions)" :key="version.id" class="my-1">
              <td>{{ displayPublishedDate(version) }}</td>
              <td>{{ version.lastUpdatedBy?.fullName }}</td>
              <td class="text-right">{{ version.publishedVersionNumber }}</td>
              <td>{{ displayExtendedVersionId(version) }}</td>
              <td><v-btn size="x-small" @click="releaseNote(version)" v-if="version.publishedNotes">{{ displayPublishedNotes(version) }}</v-btn></td>
              <td><v-btn size="x-small" icon="mdi-file-eye-outline" variant="text" @click="preview(version)"></v-btn></td>
              <td>{{ version.uses.length }}</td>
              <td>
                {{ version.id.slice(0, 8) }}...
                <CopyToClipboard :text="version.id" />
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
      <div v-else>
        <i>Ingen tidligere versioner</i>
      </div>
    </div>
  </v-sheet>
  <DimsDialog v-model="showPreview" title="Skabelon version" :width="1400">
    <VersionPreview v-if="selectedPreviewTemplate" :template="selectedPreviewTemplate" :changes="selectedPreviewChanges !== undefined ? selectedPreviewChanges : []" />
  </DimsDialog>
  <ReleaseNoteDialog
    v-if="showReleaseNote && selectedReleaseNoteTemplate"
    v-model="showReleaseNote"
    :template="selectedReleaseNoteTemplate"
    :versionNumber="selectedReleaseNoteVersionNumber"
    :readonly="true"
    @closeDialog="closeReleaseNoteDialog"
  />
</template>

<script setup lang="ts">
import VersionPreview from '@/components/Templates/Preview/VersionPreview.vue';
import ReleaseNoteDialog from '@/components/Templates/Editor/ReleaseNoteDialog.vue';
import DimsDialog from '@/components/Templates/Editor/DimsDialog.vue';
import { QuestionnaireTemplateSummary } from '@/components/Templates/QuestionnaireTemplateSummary';
import CopyToEnvironment from './CopyToEnvironment.vue';
import CopyToClipboard from '@/components/shared/CopyToClipboard.vue';
import { ref } from 'vue';
import { AdminTemplateHistory } from '@/models/AdminQuestionnaireTemplate';
import { QuestionnaireTemplate, downloadFile } from '@dims/components';
import templateServices from '@/components/Templates/templateServices';
import { DateTime } from 'luxon';
import { QuestionnaireTemplateNoteVersion } from '@/models/QuestionnaireTemplateNote';
import { QuestionDefinitionChange } from '@/components/Templates/QuestionnaireTemplateWithChanges';
import DraftTemplate from './DraftTemplate.vue';

const emit = defineEmits<{ updated: [] }>();
const { templateInfo, agreementName = undefined } = defineProps<{ templateInfo: AdminTemplateHistory, agreementName?: string }>();

const isLoading = ref(false);

const showPreview = ref(false);
const showReleaseNote = ref(false);
const selectedPreviewTemplate = ref<QuestionnaireTemplate>();
const selectedPreviewChanges = ref<QuestionDefinitionChange[]>();
const selectedReleaseNoteTemplate = ref<QuestionnaireTemplate>();
const selectedReleaseNoteVersionNumber = ref<number>();

function closeReleaseNoteDialog() {
  showReleaseNote.value = false;
}

async function excelExportList() {
  if (agreementName) {
    isLoading.value = true;

    const excelFile = await templateServices.excelExportList(templateInfo.id);
    downloadFile(excelFile, `${agreementName} ${templateInfo.name}.xlsx`);

    isLoading.value = false;
  }
}

async function preview(version: QuestionnaireTemplateSummary) {
  const templateWithChanges = await templateServices.getTemplateWithChanges(version.id);

  selectedPreviewTemplate.value = templateWithChanges.template;
  selectedPreviewChanges.value = templateWithChanges.changes;

  showPreview.value = true;
}

async function releaseNote(version: QuestionnaireTemplateSummary) {
  const templateWithChanges = await templateServices.getTemplateWithChanges(version.id);
  selectedReleaseNoteTemplate.value = templateWithChanges.template;
  selectedReleaseNoteVersionNumber.value = version.publishedVersionNumber;
  showReleaseNote.value = true;
}

function displayPublishedDate(template: QuestionnaireTemplateSummary) {
  if (template.publishedDate) {
    return DateTime.fromISO(template.publishedDate).toFormat('dd-MM-yyyy HH:mm');
  }
  return '';
}

function displayExtendedVersionId(template: QuestionnaireTemplateSummary) {
  return template.publishedVersionNumber && template.publishedDate && agreementName
    ? `${agreementName}-${template.publishedVersionNumber}-${DateTime.fromISO(template.publishedDate).toFormat('ddMMyy')}` : '';
}

function displayPublishedNotes(template: QuestionnaireTemplateSummary) {
  if (template.publishedNotes) {
    const publishNote = template.publishedNotes.find((note) => note.version === QuestionnaireTemplateNoteVersion.Publish)?.note;
    const otherNotes = template.publishedNotes.filter((note) => note.version !== QuestionnaireTemplateNoteVersion.Publish).map((note) => note.note);

    if (publishNote) {
      return `${publishNote.length > 15 ? `${publishNote.substring(0, 15)}...` : publishNote} (${otherNotes.length})`; /// ...
    }

    return `<ingen> (${otherNotes.length})  `;
  }

  return '<ingen> (0)';
}

function refresh() {
  emit('updated');
}

function sortVersions(t1:QuestionnaireTemplateSummary, t2: QuestionnaireTemplateSummary) {
  if (t1.publishedDate && t2.publishedDate) {
    return (new Date(t1.publishedDate) < new Date(t2.publishedDate)) ? 1 : -1;
  } if (t1.publishedDate) {
    return -1;
  } if (t2.publishedDate) {
    return 1;
  }
  return (new Date(t1.lastUpdated) < new Date(t2.lastUpdated)) ? 1 : -1;
}

const editName = ref(false);
const editedName = ref('');

async function updateName() {
  try {
    isLoading.value = true;
    const update = {
      name: editedName.value,
    };
    await templateServices.updateAdminTemplate(templateInfo.id, update);
    templateInfo.name = editedName.value;
    editName.value = false;
  } catch (error) {
    console.error(error);
    // store.setSnackbarText('Kan ikke opdaterer titel');
  } finally {
    isLoading.value = false;
  }
}

function cancelNameEdit() {
  editName.value = false;
  editedName.value = '';
}

function startEditName() {
  editedName.value = templateInfo.name;
  editName.value = true;
}

async function createVersion(template?: QuestionnaireTemplateSummary) {
  if (template) {
    await templateServices.createNewTemplateVersion(template.id, 'Draft');
    emit('updated');
  }
}

</script>

<style scoped>

.row-pointer :deep(tbody tr:hover) {
  cursor: pointer;
}

td {
  background-color: #E2E4E3;
  color: #63756B;
}

td:nth-child(1) {
  width: 170px;
}

td:nth-child(2) {
  min-width: 30ch;
}

td:nth-child(5) {
  min-width: 20ch;
}

td:last-child{
  font-family: monospace;
  width: 20ch;
}

h3 {
  line-height: 1.3
}
</style>
