<template>
  <h4 class="mt-6 mb-3">{{ title }}</h4>
  <div v-if="templateSummary">
    <v-table fixed-header density="compact" class="my-2">
      <thead>
        <tr>
          <th class="text-left">Senest opdateret</th>
          <th>Senest opdateret af</th>
          <th></th>
          <th></th>
          <th></th>
          <th>ID</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="templateSummary.id" class="my-1">
          <td class="text-left">{{ displayLastUpdated(templateSummary) }}</td>
          <td>{{ templateSummary.lastUpdatedBy?.fullName }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            {{ templateSummary.id.slice(0, 8) }}...
            <CopyToClipboard :text="templateSummary.id" /></td>
        </tr>
      </tbody>
    </v-table>
    <v-btn @click="editTemplate(templateSummary)" class="primary-button-mini ml-2"><v-icon>mdi-pencil-outline</v-icon>Rediger kladde</v-btn>
    <PublishDraftDialog v-if="!(agreementName === '02220022' && templateInfo.name.toLowerCase().indexOf('sandbox') > 0)" :draft="templateSummary" @updated="refresh()" />
    <!-- delete draft -->
    <ConfirmDialog
      activatorText="Slet kladde"
      text="Vil du slette kladde?"
      @confirmed="deleteDraft(templateSummary)"
    />
    <!-- publish draft disabled for 0222 sandbox #17707 -->
    <CopyToEnvironment :templateId="templateSummary.id" class="ml-2" />
  </div>
  <div v-else>
    <i>Ingen kladde</i>
  </div>
</template>

<script setup lang="ts">

import { AdminTemplateHistory } from '@/models/AdminQuestionnaireTemplate';
import { QuestionnaireTemplateSummary } from '@/components/Templates/QuestionnaireTemplateSummary';
import CopyToEnvironment from './CopyToEnvironment.vue';
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue';
import PublishDraftDialog from './PublishDraftDialog.vue';
import { useRouter } from 'vue-router';
import templateServices from '@/components/Templates/templateServices';
import { DateTime } from 'luxon';
import CopyToClipboard from '@/components/shared/CopyToClipboard.vue';

const emit = defineEmits<{ updated: [] }>();
const { templateInfo, templateSummary = undefined, agreementName = undefined } =
  defineProps<{ templateInfo: AdminTemplateHistory, templateSummary?: QuestionnaireTemplateSummary, agreementName?: string }>();

const router = useRouter();

const title = templateSummary?.publishedName ? 'Kladde, "' + templateSummary.publishedName + '"' : 'Kladde';

function displayLastUpdated(template?: QuestionnaireTemplateSummary) {
  if (template) {
    return DateTime.fromISO(template.lastUpdated).toFormat('dd-MM-yyyy HH:mm');
  }
  return '';
}

async function editTemplate(template?: QuestionnaireTemplateSummary) {
  if (template && template.state === 'Draft') {
    await router.push(`/template/${template.id}`);
  }
}

async function deleteDraft(template?: QuestionnaireTemplateSummary) {
  if (template) {
    await templateServices.deleteTemplate(template.id);
    emit('updated');
  }
}

function refresh() {
  emit('updated');
}

</script>

<style scoped>

.row-pointer :deep(tbody tr:hover) {
  cursor: pointer;
}

td {
  background-color: #E2E4E3;
  color: #63756B;
}

td:nth-child(1) {
  width: 170px;
}

td:nth-child(2) {
  min-width: 30ch;
}

td:nth-child(5) {
  min-width: 20ch;
}

td:last-child{
  font-family: monospace;
  width: 20ch;
}

h3 {
  line-height: 1.3
}
</style>
